import * as React from "react"
import { Container, Row, Col, Card } from 'react-bootstrap'
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Logo from '../components/logo'
// import siteconfig from '../../siteconfig.esm'
import locales from '../locales/index.esm'

const locale = process.env.GATSBY_LOCALE
const labels = locales[locale]

const Resources = (props) => {
  const { quizzes, siteData } = props.pageContext
  const resourcesByQuiz = quizzes.map(q => {
    let resourceCategories = []
    const resources = q.relationships?.field_quiz_resource?.reduce((acc, i) => {
      if (!i.relationships.field_resource_category?.name) return acc
      if (acc[i.relationships.field_resource_category.name]) {
        acc[i.relationships.field_resource_category.name].push(i.field_resource_link)
      } else {
        acc[i.relationships.field_resource_category.name] = [i.field_resource_link]
        resourceCategories.push(i.relationships.field_resource_category.name)
      }
      return acc
    }, {})

    const quizresources = resourceCategories.map(r => {
      return {category: r, links: resources[r]}
    })
    return Object.assign(q, {resources: quizresources})
  })

  function ResourceList (p) {
    return (
      <>
      {p.resources.filter(q => q.resources.length).map(q => (
        <div key={q.id}>
          <span id={q.slug} className="anchor"></span>
          <h2 className="mb-3">
            <Logo as="text" locale={locale}/> { q.field_short_name }
          </h2>
          <Card className="mb-3">
            {q.resources.map((res, resindex) => (
              <div key={resindex}>
                <Card.Header>
                  <h3>{ res.category }</h3>
                </Card.Header>
                <ul className="list-group list-group-flush">
                  {res.links.map((link, linkindex) => (
                    <li key={linkindex} className="list-group-item">
                      <a href={link.uri} target="_blank" rel="noreferrer">{ link.title }</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Card>
        </div>
      ))}
      </>
    )
  }
  function ResourcesBySection (p) {
    return (
      <>
      {p.sections.map(section => (
        <div key={section}>
          <h2>{section}</h2>
          {p.resources.filter(q => q.resources.length && q.section === section).map(q => (
            <div key={q.id}>
              <span id={q.slug} className="anchor"></span>
              <h3 className="mb-3">
                <Logo as="text" locale={locale}/> { q.field_short_name }
              </h3>
              <Card className="mb-3">
                {q.resources.map((res, resindex) => (
                  <div key={resindex}>
                    <Card.Header>
                      <h4>{ res.category }</h4>
                    </Card.Header>
                    <ul className="list-group list-group-flush">
                      {res.links.map((link, linkindex) => (
                        <li key={linkindex} className="list-group-item">
                          <a href={link.uri} target="_blank" rel="noreferrer">{ link.title }</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Card>
            </div>
          ))}
        </div>
      ))}
      </>
    )

  }
  function ResourcesSidebar (p) {
    return (
      <ul>
        {p.resources.map(q => (
          <li key={q.id}><a href={`#${q.slug}`} aria-label={'resource-'+q.field_short_name}><span>{ q.field_short_name }</span></a></li>
        ))}
      </ul>
    )
  }
  function ResourcesSidebarBySection (p) {
    return (
      <>
      {p.sections.map(section => (
        <div key={section}>
          <h2>{section}</h2>
          <ul>
            {p.resources.filter(q => q.section === section).map(q => (
              <li key={q.id}><a href={`#${q.slug}`} aria-label={'resource-'+q.field_short_name}><span>{ q.field_short_name }</span></a></li>
            ))}
          </ul>
        </div>
      ))}
      </>
    )
  }

  return (
    <Layout siteData={siteData} seo={{title: siteData.pageTitles.resources}}>
      <PageHeader crumbs={props.pageContext.breadcrumb} />
      <Container className="not-front resources-page sidebar-top">
        <Row className="pt-xs-0">
          <Col md={{ order: 'first', span: 8 }} xs={{ order: 'last', span: 12 }} className="pe-md-5">
            <h1>{labels.resources}</h1>
            {props.pageContext.sections
              ? <ResourcesBySection sections={props.pageContext.sections} resources={resourcesByQuiz} />
              : <ResourceList resources={resourcesByQuiz}/>}
          </Col>
          <Col md={{ order: 'last', span: 4 }} xs={{ order: 'first', span: 12 }} className="sidebar">
            <h2>
            <Logo as="text" locale={locale}/> {labels['resources-by-quiz']}
            </h2>
            {props.pageContext.sections
              ? <ResourcesSidebarBySection sections={props.pageContext.sections} resources={resourcesByQuiz}  />
              : <ResourcesSidebar  resources={resourcesByQuiz}/>}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Resources
